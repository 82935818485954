<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Создание</v-card-title>
      <v-card-text>
        <v-row class="row-d-block">
          <v-subheader>Заголовок</v-subheader>
          <v-text-field
              v-model="promo.title"
              outlined/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Кол-во промокодов</v-subheader>
          <v-text-field
              v-model="promo.count"
              outlined
              type="number"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Префикс</v-subheader>
          <v-text-field
              v-model="promo.prefix"
              outlined/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Логотип</v-subheader>
          <v-input
              type="file"
              v-model="promo.logo"
              outlined/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Сумма</v-subheader>
          <v-text-field
              v-model="promo.sum"
              outlined
              type="number"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Процент от уборки</v-subheader>
          <v-text-field
              v-model="promo.percent"
              outlined
              type="number"/>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="addPromo" :loading="loading_save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddPromo",
  data() {
    return {
      opened: false,
      promo: {},
      loading_save: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    onToggle() {
      this.promo = {}
    },
    addPromo() {
      this.loading_save = true
      this.$store.state.server.request('', {}, () => {
        this.loading_save = false
        this.opened = false
      }, (data) => {
        this.loading_save = false
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  }
}
</script>

<style scoped>

</style>