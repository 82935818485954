var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("Партнерские промо")]),_c('v-card-actions',[_c('router-link',{attrs:{"to":'/other/partner_promos/0'}},[_c('v-btn',{on:{"click":function($event){}}},[_vm._v("Создать новый")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hover":"","items":_vm.promos,"headers":_vm.promoHeader,"item-key":"id","loading":_vm.loading_promos,"footer-props":{'items-per-page-options':[-1]},"no-data-text":"Партнерских промокодов нет","loading-text":"Загрузка...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.orders.total",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(_vm.$root.printCost(item.orders.total))+" "),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("("+_vm._s(parseInt(item.orders.cnt) || 0)+" шт)")])])]}},{key:"item.n_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td')]}},{key:"item.title",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('router-link',{attrs:{"to":'/other/partner_promos/'+item.n_id}},[_vm._v(_vm._s(item.title))])],1)]}}])})],1)],1)],1),_c('AddPromo',{ref:"addPromo"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }