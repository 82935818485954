<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Партнерские промо</v-card-title>
        <v-card-actions>
          <router-link :to="'/other/partner_promos/0'">
            <v-btn @click="">Создать новый</v-btn>
          </router-link>
        </v-card-actions>
        <v-card-text>
          <v-data-table
              hover
              :items="promos"
              :headers="promoHeader"
              item-key="id"
              :loading="loading_promos"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Партнерских промокодов нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item.orders.total="{item, index}">
              <td>{{ $root.printCost(item.orders.total) }}
                <span style="white-space: nowrap">({{ parseInt(item.orders.cnt) || 0 }} шт)</span>
              </td>
            </template>
            <template v-slot:item.n_id="{item, index}">
              <td></td>
            </template>
            <template v-slot:item.title="{item, index}">
              <td>
                <router-link :to="'/other/partner_promos/'+item.n_id">{{ item.title }}</router-link>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <AddPromo ref="addPromo"/>
  </v-app>
</template>

<script>
import AddPromo from "@/views/other/partnerPromos/AddPromo";

export default {
  name: "Promos",
  components: {
    AddPromo
  },
  data() {
    return {
      promos: [],
      promoHeader: [
        {text: '#', value: 'n_id'},
        {text: 'Заголовок', value: 'title'},
        {text: 'Префикс', value: 'prefix'},
        {text: 'Сумма', value: 'nominal'},
        {text: 'Процент', value: 'maxpercent'},
        {text: 'Промокодов', value: 'certs.count'},
        {text: 'Активировано', value: 'certs.activated'},
        {text: 'Использовано', value: 'certs.spent'},
        {text: 'Заказов', value: 'orders.total'},
      ],
      loading_promos: true,
    }
  },
  methods: {
    addPromo() {
      const modal = this.$refs.addPromo
      modal.open()
    },
    getPromos() {
      this.$store.state.server.request('certificate/get', {}, (data) => {
        this.loading_promos = false;
        this.promos = data.response;
      }, (data) => {
        this.loading_promos = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    }
  },
  mounted() {
    this.getPromos()
  }
}
</script>

<style scoped>

</style>